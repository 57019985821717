//! moment.js locale configuration
//! locale : chinese (zh-cn)
//! author : suupic : https://github.com/suupic
//! author : Zeno Zeng : https://github.com/zenozeng

import moment from 'moment';

import type { TrelloWindow } from '@trello/window-types';

declare const window: TrelloWindow;

import { makeYearAwareCalendar } from './makeYearAwareCalendar';

moment.locale(window.locale, {
  months: [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ],
  monthsShort: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  weekdays: [
    '星期日',
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六',
  ],
  weekdaysShort: ['週日', '週一', '週二', '週三', '週四', '週五', '週六'],
  weekdaysMin: ['日', '一', '二', '三', '四', '五', '六'],
  longDateFormat: {
    LT: 'Ah點mm分',
    LTS: 'Ah點m分s秒',
    L: 'YYYY年MMMD日',
    LL: 'YYYY年MMMD日',
    LLL: 'YYYY年MMMD日Ah點mm分',
    LLLL: 'MMMD日',
    l: 'YYYY年M月D日',
    ll: 'YYYY年MMMD日',
    lll: 'YYYY年MMMD日Ah點mm分',
    llll: 'MMMD日',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: null, // TODO https://trello.atlassian.net/browse/PANO-2026
    LT: null, // TODO https://trello.atlassian.net/browse/PANO-2026
  },
  meridiemParse: /早上|上午|中午|下午|晚上/,
  meridiemHour(hour: any, meridiem: any) {
    const mHour = hour === 12 ? 0 : Number(hour);

    if (meridiem === '早上' || meridiem === '上午') {
      return mHour;
    } else if (meridiem === '中午') {
      return mHour >= 11 ? mHour : mHour + 12;
    } else if (meridiem === '下午' || meridiem === '晚上') {
      return mHour + 12;
    }
  },
  meridiem(hour, minute, isLower) {
    const hm = hour * 100 + minute;
    if (hm < 900) {
      return '早上';
    } else if (hm < 1130) {
      return '上午';
    } else if (hm < 1230) {
      return '中午';
    } else if (hm < 1800) {
      return '下午';
    } else {
      return '晚上';
    }
  },
  calendar: makeYearAwareCalendar({
    sameDay: '[今天]LT',
    nextDay: '[明天]LT',
    nextWeek: 'llll LT',
    lastDay: '[昨天]LT',
    lastWeek: 'llll LT',
    sameYear: 'llll LT',
    sameElse: 'll LT',
  }),
  ordinalParse: /\d{1,2}(日|月|週)/,
  // @ts-expect-error TS(2322): Type '(num: any, period: any) => string' is not as... Remove this comment to see the full error message
  ordinal(num: any, period: any) {
    switch (period) {
      case 'd':
      case 'D':
      case 'DDD':
        return `${num}日`;
      case 'M':
        return `${num}月`;
      case 'w':
      case 'W':
        return `${num}週`;
      default:
        return `${num}`;
    }
  },
  relativeTime: {
    future: '%s內',
    past: '%s前',
    s: '幾秒',
    m: '一分鐘',
    mm: '%d分鐘',
    h: '一小時',
    hh: '%d小時',
    d: '一天',
    dd: '%d天',
    M: '一個月',
    MM: '%d個月',
    y: '一年',
    yy: '%d年',
  },
});
